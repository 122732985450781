@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.big_number {
  .main {

    .value {
      font-size: rem(34px);
      line-height: rem(34px);
      font-family: $font-sonda;
    }
  }
}
