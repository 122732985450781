@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.progressbar {
  display: flex;
  align-items: center;

  :global {
    .bar {
      flex: 1 1 auto;
      height: 8px;
      border-radius: 4px;
  
      .bar-value {
        height: 100%;
        border-radius: 4px;
        position: relative;
      }
    }
    
    .value {
      font-size: rem(12px);
      font-weight: 500;
      width: rem(40px);
      text-align: right;
    }

    .value_float {
      display: inline-block;
      transform:translateX(-50%);
    }

     .end-value {
      color: $color-grey-700;
      padding-inline: rem(12px);
      font-weight: 500;
      font-size: rem(14px);
    }

  }

  @each $code, $color in $colors {
    &_color_#{$code} {
      :global .bar {
        background: lighten($color, 30);

        .bar-value {
          background: #{$color};
        }
      }
    }
  }
}