@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.company_valuation {
  width: 100%;

  .current {
    flex-wrap: wrap;
  }

  &_number {
    margin: rem(41px) 0 rem(16px) 0;

    &_currency {
      font-size: rem(15px);
      font-weight: 600;
      margin: 0 rem(6px) 0 0;
      position: relative;
    }

    &_value {
      font-size: rem(34px);
      font-weight: 700;
      margin: 0 rem(12px) 0 0;
      font-family: $font-sonda;
    }
  }
}