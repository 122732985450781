@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.box {
  :global {
    .value {
      font-family: $font-sonda;
    }
  }
  
  &:global.has-border {
    width: 100%;
    max-width: rem(250px);
    height: 100%;
    border: 1px dashed $color-grey-light;
    padding: rem(24px);
    border-radius: 12px;
  }
}