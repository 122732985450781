@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.card {
  background: $color-white;
  border-radius: 12px;

  > :global.header {
    padding: rem(24px);
  }

  &:global.has-shadow {
    border: 1px solid $color-grey-200;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }

  &:global.is-border {
    > .header {
      border-bottom: 1px solid $color-grey-200;
    }
  }

  &:global.is-no-padding {
    > .content {
      padding: 0;
    }
  }


  &:global:not(.is-border) {
    > .header {
      ~ .content {
        padding-top: 0;
      }
    }
  }

  > :global.content {
    padding: rem(24px);
    position: relative;
  }

  @include media-breakpoint-down('md') {
    > :global.content {
      padding: rem(16px);
    }
  }
}
