@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.document {
  display: flex;
  align-items: center;
  background: rgba(245, 248, 250, 0.2);
  border: 1px solid rgba(228, 230, 239, 0.7);
  border-radius: 6px;
  padding: rem(16px);

  .iconHolder {
    position: relative;

    .label {
      position: absolute;
      top: 40%;
      left: rem(-4px);
      height: rem(12px);
      border-radius: 2px;
      background: $color-orange;
      font-size: rem(7px);
      color: $color-white;
      text-align: center;
      text-transform: uppercase;
      padding: 0 rem(2px);
      display: flex;
      align-items: center;
    }
  }

  .documentNameContainer {
    flex: 1 1 auto;
    min-width: 0;
    .documentName {
      font-size: rem(14px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .documentMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.reminderModalList {
  text-align: left;
}
