@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.graph {
  position: relative;

  [class~=tooltip] {
    position: absolute;
    border-radius: 5px;


    left: auto;
    top: auto;
    box-shadow: 0 0 30px rgba(181, 181, 195, 0.7);

    transition: all 0.3s ease-in-out;
  }
}
