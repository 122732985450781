@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.status {
  font-size: rem(12px);
  padding: rem(4px) rem(10px);
  border-radius: rem(6px);

  &:global.bg- {
    &blue {
      background-color: $color-blue-50 !important;
      color: $color-blue-700 !important;
    }

    &default,
    &grey {
      background-color: $color-grey-200 !important;
      color: $color-grey-700 !important;
    }

    &outline-grey {
      color: $color-grey-700 !important;
      border: 1px dashed $color-grey-700 !important;
      background-color: transparent !important;
    }

    &grey-dark {
      background-color: $color-grey-500 !important;
      color: $color-grey-50 !important;
    }

    &red {
      background-color: $color-red-50 !important;
      color: $color-red-700 !important;
    }

    &violet {
      background-color: $color-violet-100 !important;
      color: $color-violet-700 !important;
    }

    &violet-light {
      background-color: $color-violet-50 !important;
      color: $color-violet-600 !important;
      font-weight: 500;
      border-radius: rem(16px);
    }

    &orange {
      background-color: $color-orange-light !important;
      color: $color-orange-dark !important;
    }
  }
}
