@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.item {
  position: relative;
  background-color: $color-white;
  border: 1px solid $color-grey-200;
  border-radius: 12px;
  transition: background-color 0.3s;

  :global {

    .check {
      width: rem(24px);
      height: rem(24px);
      border-radius: 50%;
    }

    .title {
      line-height: 150%;

      .optional {
        font-size: rem(14px);
        font-weight: 400;
        color: $color-grey-500
      }
    }

    .arrow {
      transition: transform 0.3s;
    }
  }
  
  &:global.is-enabled {
    color: $color-grey-700;
    
    .check {
      border: 1px dashed $color-grey-300;
    }

    &:hover {
      background-color: $color-grey-50;

      .arrow {
        transform: translateX(6px);
      }
    }
  }
  &:global.is-done {
    color: $color-grey-500;

    .check {
      border: none;
      background-color: $color-primary;
    }
  }

  &:global.is-locked {
    color: $color-grey-700;
    cursor: not-allowed;
  }
}