@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border: 0;
  display: block;

  &:global.size-normal {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
  }
  &:global.size-small {
    width: 7px;
    height: 7px;
    min-width: 7px;
    min-height: 7px;
  }
  &:global.size-large {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
  }
}