$font: 'Inter', sans-serif;
$font-sonda: 'Sonda', sans-serif;

$color-white: #FCFCFC;
$color-black: #1B1B1B;

$color-bg: #F2F2F2;


// orange
$color-orange-50: #FFDBC8;
$color-orange-100: #FFD1B8;
$color-orange-150: #FFC7A8;
$color-orange-200: #FFBD98;
$color-orange-300: #FFA978;
$color-orange-400: #FF9459;
$color-orange-500: #FF8039;
$color-orange-600: #D66C30;
$color-orange-700: #AD5727;
$color-orange-800: #85431E;
$color-orange-850: #6A3619;
$color-orange-900: #5C2E15;

$color-orange: $color-orange-500;
$color-orange-dark: $color-orange-700;
$color-orange-light: $color-orange-300;


// anthracite
$color-anthracite-50: #C4C8CD;
$color-anthracite-100: #B3B8BE;
$color-anthracite-200: #9199A1;
$color-anthracite-300: #707A85;
$color-anthracite-400: #4E5A68;
$color-anthracite-500: #2C3B4B;
$color-anthracite-600: #25323F;
$color-anthracite-700: #1E2833;
$color-anthracite-800: #171F27;
$color-anthracite-900: #10151B;

$color-anthracite: $color-anthracite-500;
$color-anthracite-dark: $color-anthracite-700;
$color-anthracite-light: $color-anthracite-300;


// violet
$color-violet-50: #F9F5FF;
$color-violet-100: #F4EBFF;
$color-violet-200: #E9D7FE;
$color-violet-300: #D6BBFB;
$color-violet-400: #B692F6;
$color-violet-500: #9E77ED;
$color-violet-600: #7F56D9;
$color-violet-700: #6941C6;
$color-violet-800: #53389E;
$color-violet-900: #2C1C5F;

$color-violet: $color-violet-500;
$color-violet-dark: $color-violet-700;
$color-violet-light: $color-violet-300;

// green
$color-green-50: #EBF6BE;
$color-green-100: #E5F3AB;
$color-green-200: #D9EE86;
$color-green-300: #CDE961;
$color-green-400: #C2E33B;
$color-green-500: #B6DE16;
$color-green-600: #99BA12;
$color-green-700: #7C970F;
$color-green-800: #5F730B;
$color-green-900: #425008;

$color-green: $color-green-500;
$color-green-dark: $color-green-700;
$color-green-light: $color-green-300;

// red
$color-red-50: #FFCBB9;
$color-red-100: #FFBCA5;
$color-red-200: #FF9E7D;
$color-red-300: #FF8055;
$color-red-400: #FF622D;
$color-red-500: #FF4405;
$color-red-600: #D63904;
$color-red-700: #AD2E03;
$color-red-800: #852303;
$color-red-900: #5C1802;

$color-red: $color-red-500;
$color-red-dark: $color-red-700;
$color-red-light: $color-red-300;


// blue
$color-blue-50: #C3D7FF;
$color-blue-100: #B2CCFF;
$color-blue-200: #90B5FF;
$color-blue-300: #6D9EFF;
$color-blue-400: #4B87FF;
$color-blue-500: #2970FF;
$color-blue-600: #225ED6;
$color-blue-700: #1C4CAD;
$color-blue-800: #153A85;
$color-blue-900: #0F285C;

$color-blue: $color-blue-500;
$color-blue-dark: $color-blue-700;
$color-blue-light: $color-blue-300;


// grey
$color-grey-50: #FAFAFA;
$color-grey-100: #F4F4F4;
$color-grey-200: #E7E9EC;
$color-grey-300: #A7AAB0;
$color-grey-500: #77797E;
$color-grey-700: #494A4D;
$color-grey-900: #2E2E2F;

$color-grey: $color-grey-500;
$color-grey-dark: $color-grey-700;
$color-grey-light: $color-grey-300;


// colors
$color-primary: $color-orange;
$color-primary-dark: $color-orange-dark;
$color-primary-light: $color-orange-light;

$color-secondary: $color-anthracite;
$color-secondary-dark: $color-anthracite-dark;
$color-secondary-light: $color-anthracite-light;

$size-sidebar: #{rem(260px)};
$size-navbar: #{rem(60px)};


$colors: (
  "white": $color-white,
  "black": $color-black,

  "primary": $color-primary,
  "primary-dark": $color-primary-dark,
  "primary-light": $color-primary-light,

  "secondary": $color-secondary,
  "secondary-dark": $color-secondary-dark,
  "secondary-light": $color-secondary-light,

  "bg": $color-bg,

  "grey-50": $color-grey-50,
  "grey-100": $color-grey-100,
  "grey-200": $color-grey-200,
  "grey-300": $color-grey-300,
  "grey-500": $color-grey-500,
  "grey-700": $color-grey-700,
  "grey-900": $color-grey-900,

  "grey": $color-grey,
  "grey-dark": $color-grey-dark,
  "grey-light": $color-grey-light,

  "orange": $color-orange,
  "orange-dark": $color-orange-dark,
  "orange-light": $color-orange-light,

  "anthracite": $color-anthracite,
  "anthracite-dark": $color-anthracite-dark,
  "anthracite-light": $color-anthracite-light,

  "violet": $color-violet,
  "violet-dark": $color-violet-dark,
  "violet-light": $color-violet-light,

  "green": $color-green,
  "green-dark": $color-green-dark,
  "green-light": $color-green-light,

  "red": $color-red,
  "red-dark": $color-red-dark,
  "red-light": $color-red-light,

  "blue": $color-blue,
  "blue-dark": $color-blue-dark,
  "blue-light": $color-blue-light,
);
