@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
$paddings: rem(24px);
$padding-md-down: rem(16px);

.data-table {
  .table-holder {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table {
    width: 100%;
    min-width: rem(500px);
    text-align: left;
    border-spacing: 0;

    :global {
      .th,
      .td {
        span {
          &.align-right {
            text-align: right;
          }
        }
      }

      .head {
        .th {
          font-size: rem(10px);
          font-weight: 500;
          color: $color-grey-light;
          text-transform: uppercase;
          padding: rem(10px);

          &.selectable {
            width: rem(45px);
          }

          &:first-child {
            padding-left: $paddings;
          }
          &:last-child {
            padding-right: $paddings;
          }

          @include media-breakpoint-down('md') {
            &:first-child {
              padding-left: $padding-md-down;
            }

            &:last-child {
              padding-right: $padding-md-down;
            }
          }
        }

        .sort {
          transition: color 0.3s;
          text-transform: uppercase;

          .icon {
            height: rem(16px);
            opacity: 0;

            &.rotate {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }

          &.is-active {
            .icon {
              opacity: 1;
            }
          }

          &.is-reverse {
            flex-direction: row-reverse;
          }

          &:hover {
            color: $color-grey-900;
          }
        }

        th:hover .sort {
          opacity: 1;
        }
      }

      .body {
        .tr.is-clickable {
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .tr.is-clickable:hover {
          background: $color-grey-50;
        }

        .td {
          border-bottom: 1px dashed $color-grey-200;
          font-size: rem(14px);
          padding: rem(12px) rem(10px);

          &.selectable {
            width: rem(45px);
          }

          &:first-child {
            padding-left: $paddings;
          }
          &:last-child {
            padding-right: $paddings;
          }

          @include media-breakpoint-down('md') {
            &:first-child {
              padding-left: $padding-md-down;
            }

            &:last-child {
              padding-right: $padding-md-down;
            }
          }
        }

        .tr.last {
          .td {
            border-bottom: 0;
          }
        }
      }

      .checkbox {
        width: rem(20px);
        height: rem(20px);
        background-color: $color-grey-200;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: transparent;
        transition: color 0.3s, background-color 0.3s;

        .line {
          width: rem(12px);
          height: rem(2px);
          background-color: currentColor;
          border-radius: 2px;
        }

        &.is-toggled {
          background-color: $color-secondary;
          color: $color-white;
        }
      }
    }
  }

  .bold {
    font-weight: bold;
    color: black;
  }

  .backgroundgGrey {
    background-color: $color-grey-100;
  }

  .noBorder td {
    border-bottom: none !important;
  }
}
