@import '@outloud/web-ui/dist/styles/_functions.scss';@import '@outloud/web-ui/dist/styles/_variables.scss';@import '@outloud/web-ui/dist/styles/_mixins.scss';@import './_variables.scss';
.item {
  display: flex;
  position: relative;
  font-size: rem(15px);
  padding-bottom: rem(8px);

  &:not(:last-child)::before {
    position: absolute;
    content: '';
    left: rem(17px);
    top: 0;
    width: 0;
    height: 100%;
    border-right: 1px dashed $color-grey-200;
  }
  
  .icon {
    position: relative;
    z-index: 2;
    width: rem(36px);
    height: rem(36px);
    background: $color-grey-200;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey-700;
  }
}